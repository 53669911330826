import { motion } from 'framer-motion'
import { FiLink2 } from 'react-icons/fi'

const Card = ({ item, onClick }) => {
  return (
    <motion.div
      layoutId={item.id}
      className="w-full py-4 md:p-4 rounded-lg md:bg-white dark:bg-transparent md:dark:bg-gray-800 md:shadow-lg md:hover:shadow-xl cursor-pointer transform transition-all duration-300 ease-out text-left"
      onClick={() => onClick(item.id)}
      whileInView={{ opacity: 1, scale: 1 }}
      initial={{ opacity: 0, scale: 0.9 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}>
      {/* Header Section */}
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-3">
          <img src={item.logo} alt={`${item.name} Logo`} className="w-12 h-12 object-contain rounded-md" />
          <motion.h2 className="text-xl font-bold text-gray-800 dark:text-gray-200 tracking-wide">
            {item.name}
          </motion.h2>
        </div>

        {item.url && (
          <a
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-1 text-orange-500 dark:text-teal-400 hover:text-teal-600 dark:hover:text-teal-300 transition-colors duration-300 mr-4">
            <FiLink2 className="text-lg" />
            <span className="font-semibold text-sm">Visit</span>
          </a>
        )}
      </div>

      {/* Tagline and Description */}
      <motion.p className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">{item.heading}</motion.p>
      <motion.p className="text-gray-600 dark:text-gray-300 text-base mb-4 leading-relaxed">{item.desc[0]}</motion.p>

      {/* Tech Stack */}
      <motion.div className="flex flex-wrap gap-2 mt-4">
        {item.techStack &&
          item.techStack.slice(0, 4).map((tech, index) => (
            <span
              key={index}
              className="px-3 py-1 dark:bg-teal-400/10 dark:text-teal-300 bg-orange-400/10 text-orange-700 text-sm rounded-md dark:shadow-md">
              {tech}
            </span>
          ))}
      </motion.div>
    </motion.div>
  )
}

export default Card
