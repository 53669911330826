import { useState } from 'react'
import { FiLink2, FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { motion } from 'framer-motion'

const CardMobile = ({ item, doShowDesc = true }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <motion.div
      layoutId={item.id}
      className="w-full py no-tap-highlight disable-focus md:p-4 rounded-lg md:bg-white dark:bg-transparent md:dark:bg-gray-800 md:shadow-lg md:hover:shadow-xl cursor-pointer transform transition-all duration-300 ease-out text-left"
      whileInView={{ opacity: 1, scale: 1 }}
      initial={{ opacity: 0, scale: 0.9 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}>
      {/* Header Section */}
      <div className="flex items-center justify-between mb-4" onClick={handleToggle}>
        <div className="flex items-start space-x-3">
          <img src={item.logo} alt={`${item.name} Logo`} className="w-12 h-12 object-contain rounded-md" />
          <div>
            <motion.h2 className="text-md font-bold text-gray-800 dark:text-gray-200 tracking-wide">
              {item.name}
            </motion.h2>
            <p className="text-gray-500 text-sm mt-1">
              {item.start} {item.end && `- ${item.end}`}
            </p>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          {item.url && (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-1 text-orange-500 dark:text-teal-400 hover:text-teal-600 dark:hover:text-teal-300 transition-colors duration-300">
              <FiLink2 className="text-lg" />
              <span className="font-semibold text-sm">Visit</span>
            </a>
          )}

          {/* Accordion Toggle Button */}
          {doShowDesc && (
            <button
              onClick={handleToggle}
              className="text-gray-600 dark:text-gray-300 focus:outline-none no-tap-highlight disable-focus">
              {isOpen ? <FiChevronUp className="text-xl" /> : <FiChevronDown className="text-xl" />}
            </button>
          )}
        </div>
      </div>

      {/* Toggleable Content */}
      {isOpen && doShowDesc && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          transition={{ duration: 0.3 }}>
          {/* Tagline and Description */}
          <motion.p className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-4">{item.heading}</motion.p>
          <motion.p className="text-gray-600 dark:text-gray-300 text-base mb-4 leading-relaxed">
            {item.desc[0]}
          </motion.p>

          {/* Tech Stack */}
          <motion.div className="flex flex-wrap gap-2 mt-4">
            {item.techStack &&
              item.techStack.slice(0, 4).map((tech, index) => (
                <span
                  key={index}
                  className="px-3 py-1 dark:bg-teal-400/10 dark:text-teal-300 bg-orange-400/10 text-orange-700 text-sm rounded-md dark:shadow-md">
                  {tech}
                </span>
              ))}
          </motion.div>
        </motion.div>
      )}
    </motion.div>
  )
}

export default CardMobile
