import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPython,
  faJs,
  faNodeJs,
  faReact,
  faGithub,
  faDocker,
  faAws,
  faGitAlt,
} from '@fortawesome/free-brands-svg-icons'
import { faDatabase, faLeaf as faLeafSolid, faCloud, faToolbox } from '@fortawesome/free-solid-svg-icons'

import { CIcon } from '@coreui/icons-react'
import { cibRedis, cibDjango, cibSentry, cibFlask, cibVueJs, cibPostman, cibMongodb } from '@coreui/icons'

const skills = {
  Languages: [
    { icon: faJs, name: 'JavaScript', color: 'text-yellow-500' },
    { icon: faPython, name: 'Python', color: 'text-blue-400' },
    { icon: cibMongodb, name: 'MongoDB', color: 'green', isCoreIcon: true },
    { icon: faDatabase, name: 'MySQL', color: 'text-blue-600' },
    { icon: cibRedis, name: 'Redis', color: 'red', isCoreIcon: true },
  ],
  WebFrameworks: [
    { icon: faNodeJs, name: 'Node.js', color: 'text-green-500' },
    { icon: faReact, name: 'React', color: 'text-blue-400' },
    { icon: faNodeJs, name: 'ExpressJs', color: 'text-gray-700', colorDark: 'dark:text-gray-200' },
    { icon: cibDjango, name: 'Django', color: 'gray', isCoreIcon: true },
    { icon: cibVueJs, name: 'VueJs', color: 'green', isCoreIcon: true },
    { icon: cibFlask, name: 'Flask', color: 'gray', isCoreIcon: true },
  ],
  DevTools: [
    { icon: faDocker, name: 'Docker', color: 'text-blue-500' },
    { icon: faGithub, name: 'GitHub', color: 'text-black' },
    { icon: faGitAlt, name: 'Git', color: 'text-orange-500' }, // Git with faGitAlt
    { icon: faAws, name: 'AWS', color: 'text-orange-400' },
    { icon: faCloud, name: 'Azure', color: 'text-blue-700' }, // Azure with cloud icon
    { icon: cibPostman, name: 'Postman', color: 'orange', isCoreIcon: true }, // Use toolbox icon for Postman
    { icon: cibSentry, name: 'Sentry', color: 'violet', isCoreIcon: true },
  ],
}

const SkillsShowcase = () => {
  return (
    <div className="px-8">
      {/* <h2 className="md:hidden text-3xl border-b-2 max-w-max border-gray-900 dark:border-gray-200 text-center text-gray-800 dark:text-gray-200 mb-8">
        Skills
      </h2> */}

      {/* Languages Section */}
      <div className="mb-8 max-w-6xl mx-auto">
        <h3 className="text-left text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4">Languages</h3>
        <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8 max-w-6xl mx-auto">
          {skills.Languages.map((skill, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-3 md:p-6 text-center transform transition-all duration-300 hover:shadow-xl">
              {!skill.isCoreIcon ? (
                <FontAwesomeIcon icon={skill.icon} className={`text-2xl md:text-4xl ${skill.color} mb-4`} />
              ) : (
                <CIcon
                  icon={skill.icon}
                  className={`text-xl w-8 h-8 md:text-xl md:h-12 md:w-12 mb-2 mx-auto`}
                  fill={skill.color}
                />
              )}
              <h3 className="text-md md:text-xl text-center font-semibold text-gray-800 dark:text-gray-200">
                {skill.name}
              </h3>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Web Frameworks Section */}
      <div className="mb-8 max-w-6xl mx-auto">
        <h3 className="text-left text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4">Web Frameworks</h3>
        <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8 max-w-6xl mx-auto">
          {skills.WebFrameworks.map((skill, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-3 md:p-6 text-center transform transition-all duration-300 hover:shadow-xl">
              {!skill.isCoreIcon ? (
                <FontAwesomeIcon
                  icon={skill.icon}
                  className={`text-2xl md:text-4xl ${skill.color} ${skill.colorDark && skill.colorDark}  mb-4`}
                />
              ) : (
                <CIcon
                  icon={skill.icon}
                  className={`text-xl w-8 h-8 md:text-xl md:h-12 md:w-12 mb-2 mx-auto`}
                  fill={skill.color}
                />
              )}
              <h3 className="text-md md:text-xl font-semibold text-gray-800 dark:text-gray-200">{skill.name}</h3>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Dev Tools Section */}
      <div className="mb-8 max-w-6xl mx-auto">
        <h3 className="text-left text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4">Dev. Tools</h3>
      </div>
      <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8 max-w-6xl mx-auto">
        {skills.DevTools.map((skill, index) => (
          <motion.div
            key={index}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-3 md:p-6 text-center transform transition-all duration-300 hover:shadow-xl">
            {!skill.isCoreIcon ? (
              <FontAwesomeIcon icon={skill.icon} className={`text-2xl md:text-4xl ${skill.color} mb-4`} />
            ) : (
              <CIcon
                icon={skill.icon}
                className={`text-xl w-8 h-8 md:text-xl md:h-12 md:w-12 mb-2 mx-auto`}
                fill={skill.color}
              />
            )}
            <h3 className="text-md md:text-xl font-semibold text-gray-800 dark:text-gray-200">{skill.name}</h3>
          </motion.div>
        ))}
      </div>
    </div>
  )
}

export default SkillsShowcase
