// LuminousEffect.js

import React, { useState, useEffect } from 'react'
import './LuminousEffect.css' // Import CSS file

const LuminousEffect = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 })

  // Update mouse position
  const updatePosition = e => {
    setPosition({ x: e.clientX, y: e.clientY })
  }

  useEffect(() => {
    document.addEventListener('mousemove', updatePosition)
    return () => {
      document.removeEventListener('mousemove', updatePosition)
    }
  }, [])

  return <div className="fixed opacity-30">{/* <div className="fixed -top-5 -left-5 luminous"></div> */}</div>
}

export default LuminousEffect
