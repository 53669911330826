import React, { useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { FaBookmark, FaBookReader, FaChessBishop, FaGithub, FaInstagram, FaLinkedin } from 'react-icons/fa'
import { AiOutlineTwitter } from 'react-icons/ai'
import { FiMail } from 'react-icons/fi'
import { RiDribbbleLine } from 'react-icons/ri'
import { SiHackerrank } from 'react-icons/si'
import BackToTopButton from './BackToTop'

const Footer = ({ isDarkMode }) => {
  return (
    <footer className="bg-transparent mt-10 text-black py-4 fixed-bottom z-10 border-t border-gray-200 dark:border-gray-800 shadow-xl footerShadow ">
      {/* Social links */}
      <BackToTopButton />
      <nav className="container mx-auto flex flex-wrap justify-center items-center ">
        <div className={` mr-4 flex flex-wrap item-center justify-center p-0.5 rounded-md`}>
          <a href="mailto:itsjatin135@gmail.com" target={'_blank'}>
            <FiMail className={`mt-1 text-red-500 w-8 h-7 transition duration-500`} />
          </a>
        </div>
        <div className={`mr-4 flex flex-wrap item-center justify-center  p-0.5 rounded-md`}>
          <a href="https://twitter.com/itsjatin135s" target={'_blank'}>
            <AiOutlineTwitter className={`mt-1 text-blue-500 w-8 h-7 transition duration-500 `} />
          </a>
        </div>
        <div className={`mr-4 flex flex-wrap item-center justify-center p-0.5 rounded-md`}>
          <a href="https://dribbble.com/itsjatin135s" target={'_blank'}>
            <RiDribbbleLine className={`mt-1 text-pink-500 w-8 h-7 transition duration-500 `} />
          </a>
        </div>
        <div className={`mr-4 flex flex-wrap item-center justify-center p-0.5 rounded-md`}>
          <a href="https://hackerrank.com/itsjatin135s" target={'_blank'}>
            <SiHackerrank className={`mt-1 text-black dark:text-white w-8 h-7 rounded-md transition duration-500 `} />
          </a>
        </div>
        <div className={`mr-4 flex flex-wrap item-center justify-center p-0.5 rounded-md`}>
          <a href="https://www.instagram.com/jatin_135s/" target="_blank">
            <FaInstagram
              className={`mt-1 text-pink-500 w-8 h-7 transform perspective-300 rotate-x-10 rotate-y-10 transition duration-500 `}
            />
          </a>
        </div>

        <div className={`mr-4 flex flex-wrap item-center justify-center p-0.5 rounded-md`}>
          <a href="https://www.linkedin.com/in/itsjatin135s/" target={'_blank'}>
            <FaLinkedin className={`mt-1 text-blue-500 w-8 h-7 transition duration-500 `} />
          </a>
        </div>
        <div className={`mr-1 flex flex-wrap item-center justify-center p-0.5 rounded-md`}>
          <a href="https://github.com/itsjatin135s" target={'_blank'}>
            <FaGithub className={`mt-1 dark:text-teal-200 text-teal-800 w-8 h-7 transition duration-500 `} />
          </a>
        </div>
      </nav>

      {/* copyright section */}
      <div className="container mx-auto flex flex-wrap justify-center items-center text-center mt-3 mb-3 select-none">
        <p className={`text-xl font-medium mt-2 ${isDarkMode ? 'text-white' : 'text-black'}`}>
          &copy; 2024{' '}
          <span
            className={`cursor-pointer hover:text-teal-700 transition-colors duration-300 ${
              isDarkMode ? 'text-teal-500' : 'text-orange-500'
            }`}>
            Jatin Saini
          </span>
          . All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
