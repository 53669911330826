import React, { useState, useEffect } from 'react'
import { FaArrowCircleUp } from 'react-icons/fa'

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      {showButton && (
        <a
          className="fixed bottom-10 lg:right-10 right-5 z-50 border-none bg-slate-100 border text-white rounded-full p-2 hover:shadow-xl duration-300"
          onClick={handleBackToTop}
          style={{ cursor: 'pointer' }}>
          <FaArrowCircleUp size={32} className="text-teal-600 bg-white rounded-full" onClick={handleBackToTop} />
        </a>
      )}
    </>
  )
}

export default BackToTopButton
