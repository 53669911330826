import React, { useState, useEffect } from 'react'
import './App.css'
import Landing from './components/Landing'
import Navbar from './components/Navbar'
import Footer from './components/footer'
import LuminousEffect from './components/luminios'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

function App() {
  const [loading, setLoading] = useState(true)
  const [isDarkMode, setIsDarkMode] = useState(false)

  useEffect(() => {
    const userTheme = localStorage.getItem('theme')
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
    if (userTheme === 'dark' || (!userTheme && systemTheme)) {
      document.documentElement.classList.add('dark')
      setIsDarkMode(true)
    } else {
      setIsDarkMode(false)
    }
  }, [])

  const themeSwitch = () => {
    if (document.documentElement.classList.contains('dark')) {
      document.documentElement.classList.remove('dark')
      localStorage.setItem('theme', 'light')
      setIsDarkMode(false)
    } else {
      document.documentElement.classList.add('dark')
      localStorage.setItem('theme', 'dark')
      setIsDarkMode(true)
    }
  }

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 4000)
  }, [])

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div
      className={`App overflow-x-hidden transition ease-in-out duration-500 ${
        isDarkMode ? 'bg-slate-900' : 'bg-gradient-to-r from-white to-blue-100'
      }`}>
      <LuminousEffect />
      {loading ? (
        <div className="relative w-screen h-screen flex items-center justify-center">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20">
            <div className="w-full h-full flex justify-between items-center">
              <div className="w-3 h-3 rounded-full bg-blue-700 bounce-animation-1"></div>
              <div className="w-3 h-3 rounded-full bg-blue-700 bounce-animation-2"></div>
              <div className="w-3 h-3 rounded-full bg-blue-700 bounce-animation-3"></div>
            </div>
          </div>
        </div>
      ) : (
        <Router>
          <Navbar isDarkMode={isDarkMode} themeSwitch={themeSwitch} setIsDarkMode={setIsDarkMode} />
          {/* <Gallery/> */}
          <Routes>
            <Route exact path="/" element={<Landing isDarkMode={isDarkMode} handleBackToTop={handleBackToTop} />} />
          </Routes>
          <Footer isDarkMode={isDarkMode} />
        </Router>
      )}
    </div>
  )
}

export default App
