import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { Helmet } from 'react-helmet'
import hero from './../assets/hero.jpg'
import hero2 from './../assets/hero2.jpg'
import AboutMe from '../pages/AboutMe'
import SkillsShowcase from './Skills'

const { useLayoutEffect, useRef } = React

function FadeIn({ children, vars }) {
  const el = useRef()
  const animation = useRef()
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      animation.current = gsap.from(el.current.children, {
        opacity: 0,
        ...vars,
      })
    })
    return () => ctx.revert()
  }, [])

  return <span ref={el}>{children}</span>
}

export default function Landing({ isDarkMode, handleBackToTop }) {
  const intro = `Hey, I'm Jatin Saini, your friendly neighborhood software developer with 3+ years of SDE Experience born and broughtup in India 🇮🇳 .
  Feel free to drop me a line with good intentions. Crafting digital excellence one line of code at a time – a full-stack engineer with a passion 
  for turning ideas into seamless, functional reality`

  useEffect(() => {
    handleBackToTop()
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jatin Saini - Intro</title>
        <link rel="canonical" href="https://jatinbagri.com/" />
        <meta
          name="description"
          content="Experienced full-stack software engineer proficient in JavaScript and Python frameworks, delivering innovative web applications. Passionate about clean code, user experiences, and driving business growth."
        />
      </Helmet>
      <FadeIn vars={{ y: -60, duration: 0.8 }}>
        <section className="body-font h-full lg:h-screen content-center">
          <div className="container mx-auto flex px-5 pt-24 pb-10 lg:pt-0 items-center justify-around flex-wrap">
            <div
              className={`w-full px-14 md:w-1/2 top-10 h-80 lg:h-96 lg:w-4/12 relative rounded-lg transition ease-in-out duration-500 
              ${isDarkMode ? 'shadow-blue-600' : 'shadow-gray-400'}
              `}>
              <img
                className="absolute hidden lg:block inset-0 mx-auto w-72 h-72 lg:w-96 lg:h-96 h-full object-cover mb-10 object-cover rounded-full"
                alt="hero"
                src={hero}
              />
              <img
                className="absolute block lg:hidden inset-0 mx-auto w-64 h-72 object-contain mb-8 object-cover rounded-full"
                alt="hero"
                src={hero2}
              />
            </div>
            <div className="text-center lg:w-3/5  md:w-11/12 relative top-12 lg:top-8">
              <FadeIn vars={{ y: 50, duration: 1.4, delay: 0.8 }}>
                <div className="flex flex-col items-center justify-center">
                  <h1
                    className={`text-2xl md:text-3xl lg:text-4xl font-semibold mb-2 text-center transition ease-in-out duration-500 mt-10 ${
                      isDarkMode ? 'text-white' : 'text-gray-800'
                    }`}>
                    <span className={` ${isDarkMode ? 'text-teal-300' : 'text-orange-500'}`}>Code</span> with a hint of
                    sarcasm.
                  </h1>
                  <h1
                    className={`text-2xl md:text-3xl lg:text-4xl font-semibold mb-2 text-center transition ease-in-out duration-500 ${
                      isDarkMode ? 'text-white' : 'text-gray-800'
                    }`}>
                    "Let's <span className={` ${isDarkMode ? 'text-teal-300' : 'text-orange-500'}`}>Build</span>{' '}
                    something <span className={` ${isDarkMode ? 'text-teal-300' : 'text-orange-500'}`}>Amazing</span>.
                  </h1>
                  {/* <p
                    className={`text-lg w-full text-right px-6 lg:px-24 transition ease-in-out duration-500 ${
                      isDarkMode ? 'text-white' : 'text-gray-600 '
                    }`}>
                    - Jatin Saini
                  </p> */}
                </div>
              </FadeIn>
              <div className="p-4 mt-1">
                <p
                  className={`mb-15 mt-6 text-lg lg:text-2xl text-justify font-bold leading-9 Abel transition ease-in-out duration-500 ${
                    isDarkMode ? 'text-white' : 'text-black'
                  }`}>
                  {intro}
                </p>
                {/* <p
                  className={`flex flex-wrap justify-start mt-3 text-lg leading-9 font-normal Abel transition ease-in-out duration-500 ${
                    isDarkMode ? 'text-white' : 'text-black'
                  }`}>
                  Thanks <strong style={{ textIndent: '10px' }}> 💕</strong>
                </p> */}
              </div>
              <div className="flex justify-center mt-2">
                <FadeIn vars={{ x: 60, duration: 0.8, delay: 0.8 }}>
                  <a
                    href="https://drive.google.com/file/d/1P_NZHTAGDx_MLwuKt5thNerf8mXlDALx/view"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3">
                    <button
                      className={`inline-flex border border-black py-2 px-6 rounded-md text-lg transition ease-in-out duration-300 focus:outline-none hover:shadow-lg hover:-translate-y-1 hover:text-white hover:scale-110 ${
                        isDarkMode
                          ? 'text-white border-gray-300 hover:border hover:border-teal-500 hover:bg-teal-600'
                          : 'text-black hover:border hover:border-orange-500 hover:bg-orange-600'
                      }`}>
                      Resume
                    </button>
                  </a>
                </FadeIn>
                <FadeIn vars={{ x: 60, duration: 0.8, delay: 1.2 }}>
                  <a
                    href="https://www.linkedin.com/in/itsjatin135s/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-3">
                    <button
                      className={`dark:bg-teal-400/10 dark:text-teal-300 bg-orange-400/10 text-orange-700 text-orange-500 border-orange-500 dark:border-teal-500 ml-4 inline-flex  border rounded-md text-lg py-2 px-6 focus:outline-none ease-in-out duration-300 transition hover:-translate-y-1 hover:scale-110`}>
                      Linkedin
                    </button>
                  </a>
                </FadeIn>
              </div>
            </div>
          </div>
        </section>
      </FadeIn>
      <AboutMe />
      <SkillsShowcase />
    </>
  )
}
