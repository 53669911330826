import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const tabVariants = {
  hidden: { opacity: 1, scale: 0.9 },
  visible: { opacity: 1, scale: 1 },
}

const FloatingNav = ({ setTab, activeTab }) => {
  return (
    <nav className="relative bg-white dark:bg-gray-800/10 shadow-lg rounded-full py-2 px-8 mt-8 w-max mx-auto">
      <ul className="flex space-x-12 text-lg font-semibold text-gray-700 dark:text-gray-300">
        <AnimatePresence>
          {['experience', 'projects', 'education'].map(tab => (
            <motion.li
              key={tab}
              className={`cursor-pointer px-4 py-3 rounded-full transition-colors duration-300 ${
                activeTab === tab
                  ? 'text-orange-800 bg-orange-100 dark:text-teal-800 dark:bg-teal-100 dark:border-teal-800 shadow-md'
                  : 'hover:text-orange-600 dark:hover:text-teal-400'
              }`}
              onClick={() => setTab(tab)}
              initial={tabVariants.hidden}
              animate={activeTab === tab ? tabVariants.visible : tabVariants.hidden}
              exit={tabVariants.hidden}
              transition={{ duration: 0.3 }}>
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </motion.li>
          ))}
        </AnimatePresence>
      </ul>
    </nav>
  )
}

export default FloatingNav
